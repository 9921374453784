<template>
  <div>
    <select-pos-model
      v-if="step === 1"
      v-model="id_pos"
      :label="$t('labels.pos')"
      custom-class="mr-2"
      @change="onPosChange"
    ></select-pos-model>
    <v-btn v-else-if="step === 3" block color="success" @click="startCommand"
      >Bắt đầu</v-btn
    >
    <input-qr-scan-model
      v-else
      ref="inputQr"
      v-model="code"
      :label="label"
      @keyupEnter="inputScan"
    ></input-qr-scan-model>

    <div v-if="step === 4" class="text-center mt-5">
      <v-alert color="error" text dark class="text-h2 mb-0">{{
        currentPickingItem.cell_id
      }}</v-alert>
      <v-alert color="green" text dark class="text-h2">
        {{ needPickQuantity }}
      </v-alert>
      <div class="text-center">
        <ImageViewer
          width="auto"
          height="80px"
          v-if="currentPickingItem.url_images"
          :url="currentPickingItem.url_images"
        />
      </div>
      <div class="font-italic fs-13px">
        {{ currentPickingItem.customer_goods_barcode }} -
        {{ currentPickingItem.sku }} -
        {{ currentPickingItem.name }}
      </div>

      <v-btn block color="warning" @click="showDialogOutOfStock" class="mt-3">
        {{ $t("labels.pickup_display_not_found") }}
      </v-btn>
    </div>

    <div v-if="step === 2" class="mt-5">
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">{{ $t("labels.program") }}</th>
              <th class="text-center">{{ $t("labels.sku") }}</th>
              <th class="text-center">{{ $t("labels.barcode") }}</th>
              <th class="text-center">{{ $t("labels.quantity") }}</th>
              <th class="text-center">{{ $t("labels.area") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in waitingItems"
              :key="`w_${index}`"
              class="text-center"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.sku }}</td>
              <td>{{ item.customer_goods_barcode }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.display_level }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <v-dialog v-model="dialogOutOfStock" persistent max-width="400px">
      <v-card v-if="dialogOutOfStock">
        <v-card-title class="text-h5 mb-5">{{
          $t("labels.pickup_display_not_found")
        }}</v-card-title>
        <v-card-text>
          <input-qr-scan-model
            v-model="outOfStockCellId"
            :label="$t('labels.position')"
            @keyupEnter="confirmOutOfStock"
          ></input-qr-scan-model>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideDialogOutOfStock">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmOutOfStock"
            :disabled="currentPickingItem.cell_id != outOfStockCellId"
          >
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import _ from "lodash";

export default {
  name: "PickupDisplay",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  data: () => ({
    id_pos: null,
    id_basket: null,
    code: null,
    currentPickingItem: {},
    detailCommand: [],
    isLoading: false,
    isActiveCommand: false,
    dialogOutOfStock: false,
    outOfStockCellId: null,
    waitingItems: [],
  }),
  computed: {
    label() {
      if (!this.id_basket) {
        return this.$t("labels.basket_code");
      }
      return this.$t("labels.uid");
    },
    step() {
      if (!this.id_pos) {
        return 1;
      } else if (!this.id_basket) {
        return 2;
      } else if (!this.isActiveCommand) {
        return 3;
      } else {
        return 4;
      }
    },
    needPickQuantity() {
      return this.currentPickingItem.can_pick >=
        this.currentPickingItem.need_pickup
        ? this.currentPickingItem.need_pickup
        : this.currentPickingItem.can_pick;
    },
  },
  watch: {
    step() {
      if (this.step === 2) {
        this.getPickupWaiting();
      }
    },
  },
  mounted() {
    this.getCurrentCommand();
  },
  methods: {
    showDialogOutOfStock() {
      this.outOfStockCellId = null;
      this.dialogOutOfStock = true;
    },
    hideDialogOutOfStock() {
      this.outOfStockCellId = null;
      this.dialogOutOfStock = false;
    },
    onPosChange() {
      setTimeout(() => {
        this.$refs.inputQr.focusInput();
      }, 200);
    },
    inputScan() {
      if (!this.id_basket) {
        return this.scanBasket();
      }
      return this.scanUid();
    },
    async scanBasket() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/get-basket-for-pickup-display",
          {
            code: this.code,
            id_pos: this.id_pos,
          }
        );
        this.id_basket = data.id;
        this.code = null;
        this.isLoading = false;

        await this.startCommand();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },

    async scanUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/pickup-display-item", {
          code: this.code,
        });
        this.code = null;
        this.isLoading = false;
        this.$vToastify.success("Đã pickup");

        if (!data || data.length === 0) {
          this.resetAll();
        } else {
          this.detailCommand = [...data];
          this.sortLocations();
        }
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },

    async confirmOutOfStock() {
      if (this.currentPickingItem.cell_id != this.outOfStockCellId) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/pickup-display-out-of-stock-position",
          {
            id_pos_location: this.currentPickingItem.id_pos_location,
            id_goods: this.currentPickingItem.id_goods,
          }
        );
        this.isLoading = false;
        this.hideDialogOutOfStock();
        this.$root.$emit("playSuccessAudio");
        if (!data || data.length === 0) {
          this.resetAll();
        } else {
          this.detailCommand = [...data];
          this.sortLocations();
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },

    async startCommand() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/pickup-display-start", {
          id_pos: this.id_pos,
          id_basket: this.id_basket,
        });
        this.isLoading = false;
        this.$vToastify.success("Bắu đầu pickup");
        this.isActiveCommand = true;
        this.detailCommand = [...data];
        this.$root.$emit("playSuccessAudio");
        this.sortLocations();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
        if (errMsg === "Không có lệnh pickup nào cần xử lý") {
          this.id_basket = null;
        }
      }
    },

    resetAll() {
      this.currentPickingItem = {};
      this.detailCommand = [];
      this.id_pos = null;
      this.id_basket = null;
      this.isActiveCommand = false;
      this.code = null;
    },

    async getCurrentCommand() {
      const { data } = await httpClient.post("/get-pickup-display-active");
      if (data && data.group_tracking) {
        this.id_pos = data.id_pos;
        this.id_basket = data.id_basket;
        this.isActiveCommand = true;
        await this.getPickingDetail({ group_tracking: data.group_tracking });
      }
    },

    async getPickingDetail({ group_tracking }) {
      const { data } = await httpClient.post("/get-pickup-display-detail", {
        group_tracking,
      });
      this.detailCommand = [...data];
      this.sortLocations();
    },

    getPickupWaiting() {
      httpClient
        .post("/get-pickup-display-waiting", { id_pos: this.id_pos })
        .then(({ data }) => {
          this.waitingItems = [...data];
        })
        .catch();
    },

    getZoneToNumber(zone) {
      return zone ? zone.charCodeAt(0) : 0;
    },

    sortLocations() {
      let locations = [...this.detailCommand];
      const turn = 1;
      const isEvenTurn = turn % 2 === 0;

      // 0. Lấy tất cả vị trí có liên kết
      let tempPositions = _.chain(locations)
        .map((l) => {
          const zone = this.getZoneToNumber(l.zone);
          const position = parseInt(l.position);
          const bff = l.bff
            ? zone + parseInt(l.bff)
            : zone + parseInt(position);
          return [position, bff];
        })
        .uniq()
        .value();
      let positions = [];
      _.each(tempPositions, (tp) => {
        let pExisted = _.find(positions, (p) => {
          return (
            (p[0] === tp[0] && p[1] === tp[1]) ||
            (p[0] === tp[1] && p[1] === tp[0])
          );
        });
        if (!pExisted) {
          positions.push(tp);
        }
      });

      // 1. Chuyển tất cả vị trí có liên kết dãy về 1 dãy ảo
      let virtualPositions = [];
      _.each(locations, (l) => {
        let bffPosition = _.find(positions, (p) => {
          return parseInt(l.position) === p[0] || parseInt(l.position) === p[1];
        });
        l.virtualPosition = Math.min(bffPosition[0], bffPosition[1]);
        if (virtualPositions.indexOf(l.virtualPosition) < 0) {
          virtualPositions.push(l.virtualPosition);
        }
      });

      // 2. Nhóm theo 1 dãy mới
      let locationGroups = _.groupBy(locations, "virtualPosition");
      virtualPositions.sort((a, b) => {
        return isEvenTurn ? a - b : b - a;
      });

      // 3. Sort từng dãy
      let orderAsc = isEvenTurn;
      let result = [];
      for (let vp of virtualPositions) {
        let vpLocations = locationGroups[`${vp}`];
        let newVpLocations = _.orderBy(
          vpLocations,
          ["indexing", "quantity"],
          [orderAsc ? "asc" : "desc", "asc"]
        );
        _.each(newVpLocations, (nvl) => {
          result.push(nvl);
        });
        orderAsc = !orderAsc;
      }

      this.currentPickingItem = { ...result[0] };
    },
  },
};
</script>

<style scoped></style>
